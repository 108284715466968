import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { SectionTitle } from 'components/section-title';
import { array, string } from 'prop-types';
import { COLORS } from 'styles/constants/colors';
import MEDIA from 'styles/media';
import Chevron from 'svgs/icons/chevron-down.svg';

import { PricingTile } from './PricingTile';

const SSectionWrapper = styled.div`
  margin: 4rem auto;
  width: 100%;
  position: relative;
  ${MEDIA.max420`
    padding: 0 0.5rem;
  `}
`;

const SOffersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-content: center;
  justify-content: center;
  padding-bottom: 60px;
  border-bottom: 1px solid ${COLORS.BILLIE_GREYISH};
  width: 100%;
  height: 100%;
  ${MEDIA.max1024`
    grid-template-columns: repeat(auto-fit, 300px);
    grid-template-rows: repeat(auto-fit, 300px);
  `}
`;

const SPanel = styled.div`
  padding: ${({ opened }) => (opened ? '2rem 0 4rem' : '0')};
  display: block;
  overflow: hidden;
  max-height: ${({ opened, height }) => (opened ? `${height + 90}px` : '0')};
  transition: all 0.3s ease-in-out;
`;

const SSectionTitle = styled(SectionTitle)`
  ${MEDIA.max420`
    font-size: 2rem;
    width: 90%;
    max-width: 250px;
    margin: 0 auto 1rem;
  `}
`;

const SButton = styled.button`
  background: transparent;
  appearance: none;
  border: none;
  position: absolute;
  right: 0;
  cursor: pointer;
  ${MEDIA.max420`
    transform: scale(0.75);
  `}
  ${MEDIA.max330`
    padding: 0;
  `}
`;

const SChevron = styled(Chevron)`
  transition: all 0.3s ease-in-out;
  transform: ${({ opened }) => (opened ? 'rotate(180deg)' : 'rotate(0)')};
`;

export const PricingSection = ({ title, offers, info }) => {
  const [opened, setOpened] = useState(false);
  const contentRef = useRef();
  const maxHeight =
    (contentRef?.current && contentRef?.current?.clientHeight) || 0;

  const offersToRender = offers.map(({ id, ...rest }) => (
    <PricingTile key={id} {...rest} />
  ));

  const handleClick = () => setOpened(!opened);

  useEffect(() => {
    setOpened(true);
  }, []);

  return (
    <SSectionWrapper>
      <SButton onClick={handleClick}>
        <SChevron opened={opened} />
      </SButton>
      <SSectionTitle title={title} />
      <SPanel opened={opened} height={(opened && maxHeight) || 0}>
        <SOffersWrapper ref={contentRef}>{offersToRender}</SOffersWrapper>
        <p>{info}</p>
      </SPanel>
    </SSectionWrapper>
  );
};

PricingSection.propTypes = {
  title: string.isRequired,
  offers: array.isRequired,
  info: string,
};
