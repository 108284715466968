import React from 'react';
import { PricingView } from 'views/pricing';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import { Layout } from 'layouts';

const PricingPage = () => (
  <Layout>
    <PricingView />
  </Layout>
);

export default PricingPage;

export const Head = () => <SEO tags={TAGS.cennik} />;
