import React from 'react';
import styled from 'styled-components';
import { SECTIONS } from 'constants/sections';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { STYLES_CONSTANTS } from 'styles/constants/main';
import MEDIA from 'styles/media';

const SSectionWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 15rem 0 0;
  ${MEDIA.max1024`
    margin-top: 8rem;
    flex-direction: column;
    min-height: 300px;
  `}
`;

const SImage = styled(GatsbyImage)`
  background-color: ${STYLES_CONSTANTS.COLORS.BEIGE};
  flex: 3;
  max-width: 1081px;
  height: 23.1875rem;
  width: 100%;
  img {
    object-fit: cover;
    object-position: center left !important;
  }
`;

const STitleWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const STitle = styled.h1`
  font-weight: bold;
  font-size: 5rem;
  margin-bottom: 0;
  line-height: 0.9;
  font-family: 'Abhaya Libre', serif;
  position: absolute;
  top: 2.25rem;
  display: flex;
  flex-direction: column;
  z-index: 1;
  ${MEDIA.max1200`
      font-size: 5.5rem;
    `}
  ${MEDIA.max1024`
      font-size: 4rem;
      position: static;
      margin-bottom: 2rem;
      text-align: center;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      span {
        padding: 0 0.5rem;
      }
    `}
    ${MEDIA.max420`
      font-size: 3.5rem;
    `}
`;

export const MainSection = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "full-offer.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1081, quality: 100, formats: [WEBP])
        }
      }
    }
  `);

  return (
    <SSectionWrapper id={SECTIONS.HOME.slice(1)}>
      <STitleWrapper>
        <STitle>
          <span>Cennik</span>
          <span>Zabiegów</span>
          <span>Medycyny Estetycznej</span>
        </STitle>
      </STitleWrapper>
      <SImage
        image={data.image.childImageSharp.gatsbyImageData}
        alt="oferta zabiegów medycyny estetycznej IDEALE"
      />
    </SSectionWrapper>
  );
};
