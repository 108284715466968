import React from 'react';
import styled from 'styled-components';
import PRICING from 'jsons/pricing.json';
import MEDIA from 'styles/media';

import { MainSection } from './components/MainSection';
import { PricingSection } from './components/PricingSection';
import { FaqSection } from '../home/faq-section';

import faqData from 'jsons/faq-pricing.json';

const SPageWrapper = styled.div`
  max-width: 88.25rem;
  width: 80%;
  margin: 0 auto 2rem;
  ${MEDIA.max420`
    width: 100%;
  `}
`;

const SWrapper = styled.div`
  ${MEDIA.max420`
      width: 90%;
      margin: 0 auto;
  `}
`;

export const PricingView = () => {
  return (
    <SPageWrapper>
      <SWrapper>
        <MainSection />
      </SWrapper>
      {PRICING.sections.map(({ title, items, info }) => (
        <PricingSection key={title} title={title} offers={items} info={info} />
      ))}
      <FaqSection faqData={faqData}/>
    </SPageWrapper>
  );
};
