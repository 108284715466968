import React, { useEffect, useState } from 'react';
import { MdAdd, MdRemove } from 'react-icons/md';
import styled from 'styled-components';
import { COLORS } from 'styles/constants/colors';

const AccordionSection = styled.div`
  border: 2px solid ${COLORS.CREAMY};
  margin-bottom: 1rem;
`;

const AccordionTitle = styled.h3`
  background-color: ${COLORS.WHITE};
  color: ${COLORS.DARK};
  cursor: pointer;
  padding: 1rem;
  display: flex;
  font-weight: bold;
  align-items: center;
  font-size: 1.5rem;
  font-family: 'Abhaya Libre', serif;
`;

const AccordionContent = styled.div`
  display: none;
  background-color: ${COLORS.WHITE};
  padding: 1rem;
  font-size: 1.125rem;
  font-family: 'Source Sans Pro', sans-serif;
  color: ${COLORS.GREY};
  &.active {
    display: block;
  }
`;

const Icon = styled.span`
  margin-right: 1rem;
  color: ${COLORS.GOLD}; // Set your own gold color here
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px; // or use 'large' if you have a size mapping
`;

export const Accordion = ({ title, content, index, totalAccordions }) => {
  const [openAccordions, setOpenAccordions] = useState(new Set());

  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };

  useEffect(() => {
    // Initialize with only the first accordion open
    const initialOpen = new Set();
    initialOpen.add(0); // Adds the first accordion index
    setOpenAccordions(initialOpen);
  }, [totalAccordions]);

  const toggle = (index) => {
    const newOpenAccordions = new Set(openAccordions);
    if (newOpenAccordions.has(index)) {
      newOpenAccordions.delete(index);
    } else {
      newOpenAccordions.add(index);
    }
    setOpenAccordions(newOpenAccordions);
  };

  const isActive = openAccordions.has(index);

  return (
    <AccordionSection>
      <AccordionTitle onClick={() => toggle(index)}>
        <Icon>{isActive ? <MdRemove /> : <MdAdd />}</Icon>
        {title}
      </AccordionTitle>
      <AccordionContent
        className={isActive ? 'active' : ''}
        dangerouslySetInnerHTML={createMarkup(content)}
      />
    </AccordionSection>
  );
};
