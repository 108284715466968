import React from 'react';
import styled from 'styled-components';
import { EXTERNAL_LINKS } from 'constants/externalLinks';
import { Link } from 'gatsby';
import { string } from 'prop-types';
import { COLORS } from 'styles/constants/colors';
import { STYLES_CONSTANTS } from 'styles/constants/main';
import MEDIA from 'styles/media';
import { gtagEvent } from 'utils/utils';

const SLinkButton = styled(Link)`
  text-decoration: none;
  color: ${COLORS.CREAMY};
  font-weight: bold;
`;

const SBookingLink = styled.a`
  font-weight: bold;
  justify-self: end;
  color: ${STYLES_CONSTANTS.COLORS.CREAMY};
  text-decoration: none;
`;

const SWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  border: 2px solid ${COLORS.CREAMY};
  box-shadow: ${STYLES_CONSTANTS.BOX_SHADOWS.BLACK_LIGHT};
  padding: 2rem 1.5rem;

  ${MEDIA.max1024`
    height: auto;
    width: auto;
    justify-self: center;
    grid-template-columns: 1fr; /* Stack elements on smaller screens */
  `}
  ${MEDIA.max768`
    order: 2;
  `}
`;

const STitle = styled.h3`
  font-size: 1.4rem;
  font-family: 'Abhaya Libre', serif;
`;

const SPackage = styled.h4`
  font-size: 1rem;
  font-family: 'Abhaya Libre', serif;
  margin-top: 1rem;
`;

const SPrice = styled.p`
  justify-self: end;
  span {
    font-weight: bold;
  }
`;

const SPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-left: auto;
`;

export const PricingTile = ({ name, price, href, pack }) => {
  const uniqueLinkId = `booking-link-${name}`;
  return (
    <SWrapper>
      <div>
        <STitle>{name}</STitle>
        {href && (
          <SLinkButton to={`/oferta/${href}`}>Dowiedz się więcej</SLinkButton>
        )}
        {pack && <SPackage>{pack}</SPackage>}
      </div>
      <SPriceWrapper>
        <SPrice>
          Cena: <span>{price}</span>
        </SPrice>
        <SBookingLink
          data-unique-link-id={uniqueLinkId}
          target="_blank"
          rel="noopener noreferrer"
          href={EXTERNAL_LINKS.BOOKSY_BOOKING_APP}
          onClick={gtagEvent}
        >
          Zarezerwuj wizytę
        </SBookingLink>
      </SPriceWrapper>
    </SWrapper>
  );
};

PricingTile.propTypes = {
  name: string.isRequired,
  price: string.isRequired,
  href: string.isRequired,
  pack: string.isRequired,
};
